<template>
  <div class="container" style="padding:40px 0">
    <el-card class="box-card" v-if="isVerified">
      <div slot="header" class="clearfix">
        <span>{{ $t("confirm_your_email.title") }}</span>
      </div>
      <div>
        <el-alert
          class="margin-bottom:20px"
          v-if="message === 203"
          :title="$t('confirm_your_email.confirm_email_successfully')"
          type="success"
          :description="
            $t('confirm_your_email.confirm_email_successfully_content')
          "
          show-icon
          :closable="false"
          style="margin-bottom:20px"
        >
        </el-alert>
        <el-alert
          class="margin-bottom:20px"
          v-if="message === 202"
          :title="$t('confirm_your_email.confirm_email_successfully')"
          type="success"
          :description="
            $t('confirm_your_email.confirm_email_successfully_content')
          "
          show-icon
          :closable="false"
          style="margin-bottom:20px"
        >
        </el-alert>
        <div class="text-center">
          <router-link :to="{ name: 'SATList' }">
            <el-button type="success">{{
              $t("confirm_your_email.back_to_landing_page")
            }}</el-button>
          </router-link>
        </div>
      </div>
    </el-card>
    <el-card class="box-card" v-else>
      Loading...
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import User from "@/apis/user.js";
import { Message, Loading } from "element-ui";

export default {
  metaInfo() {
    return {
      title: "Email Verify - TestAdmit"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data: () => {
    return {
      message: 0,
      isVerified: false
    };
  },
  computed: {
    ...mapState("user", ["lang", "profile"]),
    emailIsConfirmed() {
      return this.profile.email_verified_at ? true : false;
    },
    id() {
      let id = "";
      if (this.$route.params.id) {
        id = this.$route.params.id;
      }
      return id;
    },
    hash() {
      let hash = "";
      if (this.$route.params.hash) {
        hash = this.$route.params.hash;
      }
      return hash;
    }
  },
  watch: {
    profile() {
      console.log(this.profile && !this.profile.email_verified_at);
      console.log(this.profile);
      console.log(!this.profile.email_verified_at);
      if (this.profile && !this.profile.email_verified_at) {
        this.verifyEmail();
      } else {
        this.isVerified = true;
        this.message = 203;
      }
    }
  },

  mounted() {},

  methods: {
    async verifyEmail() {
      try {
        const res = await User.verifyEmail(this.id, this.hash);
        this.message = 202;
        this.isVerified = true;
        window.location.reload();
      } catch (err) {
        if (err.data.message === "Unauthorized") {
          Message.error("Unauthorized,please resend verification email.");
        }

        if (err.data.message === "Token is expired") {
          Message.error("Token is expired,please resend verification email.");
        }
        this.isVerified = true;
        // this.$router.push({ name: "Profile" });
      }
    }
  }
};
</script>

<style scoped></style>
